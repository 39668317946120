.coeff {
    min-width: 4rem;
    max-width: 4rem;
}

.formula {
    min-width: 6rem;
    max-width: 12rem;
}

.mass {
    min-width: 8rem;
    max-width: 12rem;
}

.yield-group {
    min-width: 300px;
    max-width: 45%;
}

.btn.nohover:hover {
    color: white;
}

@media only screen and (max-width: 768px) {

    .yield-group {
        max-width: 100%;
    }

    button {
        font-size: .8rem !important;
    }
}

@media only screen and (max-width: 1000px) {
    .yield-divider {
        display: none;
    }
}

.button-row > button {
    display: flex;
    align-items: center;
}

.yield-divider {
    max-width: 5%;
}
